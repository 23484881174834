import React, { ReactNode } from 'react';
import { Image, Link, Text } from '@belong/ui';
import { clsx } from 'clsx';
import css from './home-cta.module.css';

/**
 * Original component: packages/belong-web/src/pages/Home/Components/HomeCta/HomeCta.js
 */

type Props = {
  title: string | ReactNode;
  body: string | ReactNode;
  bodyClassName?: string;
  src: string;
  imgContainerClassName?: string;
  imgClassName?: string;
  ctaLabel?: string | ReactNode;
  ctaHref: string;
  isInverted?: boolean;
  maxWidth?: number;
};

export function HomeCta({
  title,
  body,
  bodyClassName,
  src,
  imgContainerClassName,
  imgClassName,
  ctaLabel,
  ctaHref,
  isInverted = false,
}: Props) {
  const textAlignClassName = clsx('text-center', isInverted ? 'md:text-right' : 'md:text-left');

  return (
    <div
      className={clsx(
        'flex flex-col items-center w-full mb-5xl md:justify-center',
        isInverted ? 'md:flex-row-reverse' : 'md:flex-row'
      )}
    >
      {src && (
        <div className={clsx('w-full mb-sm md:mb-0', imgContainerClassName)}>
          <div className={clsx('w-full', imgClassName)}>
            <Image src={src} alt="" />
          </div>
        </div>
      )}
      <div
        className={clsx(
          'flex flex-col items-center',
          css.contentWrapper,
          isInverted ? 'md:items-end' : 'md:items-start'
        )}
      >
        <div className="mb-xs">
          <Text variant="h3" fontWeight="semibold" className={textAlignClassName}>
            {title}
          </Text>
        </div>
        <div>
          <Text className={clsx('w-full', textAlignClassName, bodyClassName)}>{body}</Text>
        </div>
        {ctaLabel && (
          <span className="mt-lg">
            <Link href={ctaHref} variant="solid" size="large">
              {ctaLabel}
            </Link>
          </span>
        )}
      </div>
    </div>
  );
}
