import { PATHS } from '@belong/common';
import { Image, Link, Text } from '@belong/ui';
import clsx from 'clsx';
import { HOME_PAGE_STRINGS } from '../../home-page.strings';
import styles from './ReferralCard.module.css';
import { REFERRALS_STRINGS } from './ReferralCard.strings';

export function ReferralCard() {
  return (
    <div
      className={clsx(
        'relative w-full flex md:justify-between items-center py-2xl md:py-0 border border-solid border-green rounded overflow-hidden flex-col md:flex-row md:pr-sm',
        styles.cardContainer
      )}
    >
      <div className={clsx('absolute top-0 md:inset-x-0 md:top-auto z-0', styles.rainBox)}>
        <Image src="/referral/referral_money_rain_img.svg" alt="" />
      </div>
      <div className="z-10 pb-lg md:pb-0">
        <Text variant="h3" className={clsx('mb-xs whitespace-pre-line', styles.mainText)} fontWeight="semibold">
          {REFERRALS_STRINGS['message.firstSentence']}{' '}
          <Text as="span" variant="h2" fontWeight="semibold">
            {REFERRALS_STRINGS['message.value']}
          </Text>
          <br />
          {REFERRALS_STRINGS['message.secondSentence']}
        </Text>
        <Text variant="body">{HOME_PAGE_STRINGS['referralCard.subtitle']}</Text>
      </div>
      <div className={clsx('flex items-center z-10', styles.link)}>
        <Link href={PATHS.REFERRAL_LANDING} variant="solid" className="flex justify-center items-center flex-1">
          {REFERRALS_STRINGS['cta']}
        </Link>
      </div>
    </div>
  );
}
